const Blog_posts = [
	{
		Date: "2021-03-29",
		Title: "running cycle #1",
		PicPath: "assets/blog pics/cycle.gif",
		bread:
			"",
	},
	{
		Date: "2021-03-23",
		Title: "A start",
		PicPath: "assets/blog pics/slugman.png",
		bread:
			"This is the first blog post. I am hoping that this tab will help me be more productive and push more stuff i make out there on the internet.",
	},
];

export default Blog_posts;
